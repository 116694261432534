import { CookieItem as CookieItemAlejandria, getCookieConfig } from '@foodology-co/alejandria';
import Cookies from 'js-cookie';
import { ENVIRONMENT } from '../config/app';

export enum CookieItemExtra {}

export type CookieItem = `${CookieItemAlejandria | CookieItemExtra}`;

export const setCookie = (item: CookieItem, value: string): void => {
  const cookieConfig = getCookieConfig(ENVIRONMENT ?? 'development');
  Cookies.set(`${cookieConfig.env}.${item}`, value, cookieConfig.options);
};

export const getCookie = (item: CookieItem): string | undefined => {
  const cookieConfig = getCookieConfig(ENVIRONMENT ?? 'development');
  return Cookies.get(`${cookieConfig.env}.${item}`);
};

export const removeCookie = (item: CookieItem): void => {
  const cookieConfig = getCookieConfig(ENVIRONMENT ?? 'development');
  Cookies.remove(`${cookieConfig.env}.${item}`, cookieConfig.options);
};
