import { KOSEnvironment, KOSTopbar } from '@foodology-co/alejandria';
import React from 'react';
import { ENVIRONMENT } from '../../../../config/app';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { getUserLogOut } from '../../../../routes/components/Session/services/user.service';
import { getCookie } from '../../../../utils/cookie';
import { setShowMenu } from '../../appLayout.slice';

const Header = (): React.ReactElement => {
  const dispatch = useAppDispatch();
  const showMenu = useAppSelector((state) => state.appLayout.showMenu);
  const title = useAppSelector((state) => state.header.title);
  const session = useAppSelector((state) => state.session.user);

  const handleToggleMenu = React.useCallback(() => {
    dispatch(setShowMenu(!showMenu));
  }, [dispatch, showMenu]);

  return (
    <KOSTopbar
      title={title}
      environment={(ENVIRONMENT ?? 'development') as KOSEnvironment}
      hamburgerMenu={{
        open: showMenu,
        onClick: handleToggleMenu,
      }}
      user={{
        name: session?.profile?.name ?? '',
        avatarUrl: session?.profileImage,
        companyId: getCookie('companyId') ?? 'fdgy',
      }}
      accountMenu={{
        helpCenterLink:
          'https://foodology.notion.site/Centro-de-ayuda-para-cocinas-f49c4322877e428f907652f9b3d2783c',
        logOut: {
          redirect: `${process.env.REACT_APP_KITCHEN_DISPLAY_URL}/login`,
          onClick: getUserLogOut,
        },
      }}
    />
  );
};

export default Header;
