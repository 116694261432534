import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import React from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { Provider } from 'react-redux';
import Analytics, { AmplitudeEvent } from './analytics';
import SurveyModal from './components/SurveyModal';
import Theme from './config/theme';
import Router from './routes';
import store from './store';
import { localizeDayjs } from './utils/date.util';

const App = (): React.ReactElement => {
  const { i18n } = useTranslation();

  localizeDayjs(i18n.resolvedLanguage ?? '');

  const getDeviceType = () => {
    if (isMobile) {
      return 'mobile';
    } else if (isTablet) {
      return 'tablet';
    } else {
      return 'desktop';
    }
  };

  React.useEffect(() => {
    const deviceType = getDeviceType();
    Analytics.logEvent(AmplitudeEvent.VISIT, {
      device: deviceType,
    });
  }, []);

  return (
    <Provider store={store}>
      <ThemeProvider theme={Theme}>
        <SurveyModal />
        <CssBaseline />
        <Router />
      </ThemeProvider>
    </Provider>
  );
};
export default App;
