import { ApiVersion, getHttp, postHttp } from '../../../../utils/http';
import Session from '../models/session';
import User from '../models/user';
import UserResponse from '../models/user-response';

const getUser = async (session: Session): Promise<User> => {
  const userResponse = await getHttp(
    process.env.REACT_APP_KITCHEN_DISPLAY_URL ?? '',
    ApiVersion.API,
    'userInfo',
  );

  const userJson: UserResponse = await userResponse.json();

  const user: User = {
    _id: userJson._id,
    profile: userJson.profile,
    profileImage: userJson.profileImage,
    rolName: userJson.rol,
  };

  return user;
};

const UserService = {
  getUser,
};

export const getUserLogOut = () => {
  postHttp(process.env.REACT_APP_KITCHEN_DISPLAY_URL ?? '', ApiVersion.API, 'user/log-out');
};

export default UserService;
