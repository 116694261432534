import { getCookie, removeCookie } from '../../../../utils/cookie';
import Session from '../models/session';

export class SessionNotFoundError extends Error {
  constructor(msg: string) {
    super(msg);
    Object.setPrototypeOf(this, SessionNotFoundError.prototype);
  }
}

export const sessionValidator = (session: Session) => {
  const url = process.env.REACT_APP_URL;
  const kds = process.env.REACT_APP_KITCHEN_DISPLAY_URL;
  const redirect = `${kds}/login?redirect=${url}${window.location.pathname}`;
  if (!session) {
    window.location.href = redirect;
    throw new SessionNotFoundError('Cookie session not found');
  }
  const { userId, loginToken } = session;
  if (!userId || !loginToken) {
    window.location.href = redirect;
    throw new SessionNotFoundError('Cookie session not found');
  }
};

const getSession = (): Session => {
  const userId = getCookie('userId');
  const loginToken = getCookie('loginToken');
  const scope = getCookie('scope');
  const companyId = getCookie('companyId');
  const authorization = getCookie('authorization');

  const session: Session = {
    userId: userId ?? '',
    loginToken: loginToken ?? '',
    scope: scope ?? '',
    companyId: companyId ?? 'fdgy',
    authorization: authorization ?? '',
  };

  sessionValidator(session);

  return session;
};

const removeSession = () => {
  const userId = getCookie('userId');
  if (userId) {
    removeCookie('userId');
  }

  const loginToken = getCookie('loginToken');
  if (loginToken) {
    removeCookie('loginToken');
  }
};

const SessionService = {
  getSession,
  removeSession,
};

export default SessionService;
