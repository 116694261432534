import { KOSEnvironment, KOSFavIcon } from '@foodology-co/alejandria';
import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import AppLayout from '../components/AppLayout';
import Spinner from '../components/Spinner';
import { ENVIRONMENT } from '../config/app';
import { useAppSelector } from '../hooks/useAppSelector';
import NotFoundPage from '../pages/NotFound';
import Session from './components/Session';

const ProductView = lazy(() => import('../pages/ProductView'));
const RecipeBook = lazy(() => import('../pages/RecipeBook'));
const ProductForm = lazy(() => import('../pages/ProductForm'));
const ProductEdit = lazy(() => import('../pages/ProductEdit'));
const MenuForm = lazy(() => import('../pages/MenuForm'));
const OnAndOffView = lazy(() => import('../pages/OnAndOff'));
const RecipeForm = lazy(() => import('../pages/RecipeForm'));
const RecipeReplace = lazy(() => import('../pages/RecipeReplace'));

const Menu = lazy(() => import('../pages/Menu/index'));
const MenuManager = lazy(() => import('../pages/MenuManager/index'));
const MenuStoresManager = lazy(() => import('../pages/MenuStoresManager/index'));
const ViewOnly = lazy(() => import('../pages/ViewOnly/index'));

const Replicate = lazy(() => import('../pages/ProductEdit/Replicate'));

const Router = (): React.ReactElement => {
  const session = useAppSelector((state) => state.session.session);

  return (
    <BrowserRouter>
      {session && (
        <KOSFavIcon
          environment={(ENVIRONMENT ?? 'development') as KOSEnvironment}
          companyId={session.companyId}
        />
      )}
      <AppLayout>
        <Session>
          <Routes>
            <Route path="/" element={<Navigate replace to="/recipe-book"></Navigate>} />
            <Route
              path="/recipe-form"
              element={
                <Suspense fallback={<Spinner></Spinner>}>
                  <RecipeForm />
                </Suspense>
              }
            ></Route>
            <Route path="/menu/upload" element={<MenuForm></MenuForm>} />
            <Route
              path="/product/:country/:sku/admin"
              element={
                <Suspense fallback={<Spinner></Spinner>}>
                  <ProductView></ProductView>
                </Suspense>
              }
            ></Route>
            <Route
              path="/product/:country/:sku"
              element={
                <Suspense fallback={<Spinner></Spinner>}>
                  <ProductView></ProductView>
                </Suspense>
              }
            ></Route>
            <Route
              path="/product/:country/:sku/recipe-replace"
              element={
                <Suspense fallback={<Spinner></Spinner>}>
                  <RecipeReplace></RecipeReplace>
                </Suspense>
              }
            ></Route>
            <Route
              path="/recipe-book/admin"
              element={
                <Suspense fallback={<Spinner></Spinner>}>
                  <RecipeBook></RecipeBook>
                </Suspense>
              }
            ></Route>
            <Route
              path="/recipe-book"
              element={
                <Suspense fallback={<Spinner></Spinner>}>
                  <RecipeBook></RecipeBook>
                </Suspense>
              }
            ></Route>
            <Route
              path="/:country/:sku/edit"
              element={
                <Suspense fallback={<Spinner></Spinner>}>
                  <ProductEdit />
                </Suspense>
              }
            ></Route>
            <Route
              path="/:country/:type/:assembledType"
              element={
                <Suspense fallback={<Spinner></Spinner>}>
                  <ProductForm />
                </Suspense>
              }
            />
            <Route
              path="/on-and-off"
              element={
                <Suspense fallback={<Spinner></Spinner>}>
                  <OnAndOffView />
                </Suspense>
              }
            ></Route>
            <Route
              path="/on-and-off/admin"
              element={
                <Suspense fallback={<Spinner></Spinner>}>
                  <OnAndOffView />
                </Suspense>
              }
            ></Route>
            <Route
              path="/menu/:id"
              element={
                <Suspense fallback={<Spinner></Spinner>}>
                  <Menu />
                </Suspense>
              }
            ></Route>
            <Route
              path="/menu-manager"
              element={
                <Suspense fallback={<Spinner></Spinner>}>
                  <MenuManager />
                </Suspense>
              }
            ></Route>
            <Route
              path="/menu-stores-manager/:menu/:brandId"
              element={
                <Suspense fallback={<Spinner></Spinner>}>
                  <MenuStoresManager />
                </Suspense>
              }
            ></Route>
            <Route
              path="/view-only/:menuId/:brandId"
              element={
                <Suspense fallback={<Spinner></Spinner>}>
                  <ViewOnly />
                </Suspense>
              }
            ></Route>
            <Route path="/recipe-book/admin/:country/replicate" element={<Replicate />}></Route>
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Session>
      </AppLayout>
    </BrowserRouter>
  );
};

export default Router;
